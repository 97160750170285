import useSWR from "swr";
import { get } from "lodash";

const InfoBox = () => {
  const { data, error } = useSWR("/api/lib/v1/banners?type=10", (url) =>
    fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const content = get(data, "[0].text");
  return (
    <div
      style={{ textAlign: "center", minHeight: '44px', backgroundColor: 'black' }}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

export default InfoBox;
