import useCart from "grandus-lib/hooks/useCart";
import { get, map } from "lodash";
import dynamic from "next/dynamic";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

import styles from "components/header/Header.module.scss";

import Link from "next/link";

const CartButton = () => {
  const { cart } = useCart();
  return (
    <div className={`${styles.cart} ${styles.item}`}>
      <Link href="/kosik" as={`/kosik`}>
        <a className={styles.iconCart}>
          <span className={styles.cartCount}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28.982"
              height="26.301"
              viewBox="0 0 28.982 26.301"
            >
              <g transform="translate(0 0)">
                <path
                  className="iconColor"
                  d="M303.809,197.223H286.443l-2.092-14.265-5.514-4.088,1.43-1.928,6.315,4.682,1.936,13.2h13.452l2.721-10.132H290.437v-2.4h17.382Z"
                  transform="translate(-278.836 -176.941)"
                />
                <circle
                  className="iconColor"
                  cx="2.531"
                  cy="2.531"
                  r="2.531"
                  transform="translate(8.432 21.239)"
                />
                <circle
                  className="iconColor"
                  cx="2.531"
                  cy="2.531"
                  r="2.531"
                  transform="translate(19.467 21.239)"
                />
              </g>
            </svg>
            {get(cart, "pieceCount", 0) ? (
              <span className={styles.cartCount}>
                {get(cart, "pieceCount", 0)}
              </span>
            ) : (
              ""
            )}
          </span>
        </a>
      </Link>
      { get(cart, "items", []).length > 0 ? (
        <div className={styles.cartItems}>
            {map(cart.items, (item) => {
              return (
                <div
                  key={`cart-item-${item?.id}`}
                  className={styles.cartItem}
                >
                  <Link href="/produkt/[id]" as={`/produkt/${item?.product.urlTitle}`} scroll={true} >
                    <a>
                      <Image photo={item?.product.photo} size={"70x70"} type={"jpg"} className={styles.image}/>
                      <h4>{item?.product.name}</h4>
                      <div className={styles.price}>
                        {item?.priceTotalData.priceFormatted}
                      </div>
                    </a>
                  </Link>
                </div>
              );
            })}
        </div>
      ) : ("")}
      
    </div>
  );
};

export default CartButton;
