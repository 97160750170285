import styles from "./ProductRow.module.scss";
import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";
import Price from "grandus-lib/components-atomic/price/Price";
import { truncate } from "lodash";

const RowProduct = ({ name, brand, urlTitle, photo, finalPriceData, onCloseClick }) => (
  <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
    <a 
      className={styles.product}
      onClick={onCloseClick}
    >
      <Image photo={photo} size={"250x250"} type={"jpg"} />
      <div className={styles?.right}>
        <span className={styles.name}>
          {truncate(name, {
            length: 50,
            omission: " ...",
          })}
        </span>
        <Price
          priceData={finalPriceData}
          className={styles.price}
          options={{ hideVatPrice: true }}
        />
      </div>
    </a>
  </Link>
);

export default RowProduct;
