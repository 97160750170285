import Link from "next/link";

import useCompare from "grandus-lib/hooks/useCompare";
import { isEmpty } from "lodash";

const CompareButton = ({ styles }) => {
  const { compare, isLoading: isLoadingCompare } = useCompare();
  return (
    <Link href="/porovnanie" as={`/porovnanie`}>
      <a className={styles.item}>
        <span className={styles?.count}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35.866"
            height="25.747"
            viewBox="0 0 35.866 25.747"
          >
            <path
              className="iconColor"
              d="M248.228,330.781H241a3.383,3.383,0,0,0-6.462,0h-7.229s-7.473,11.767-7.473,15.894a7.473,7.473,0,1,0,14.946,0c0-3.067-4.125-10.349-6.245-13.89h6a3.383,3.383,0,0,0,6.462,0h6c-2.12,3.541-6.245,10.823-6.245,13.89a7.473,7.473,0,1,0,14.946,0C255.7,342.548,248.228,330.781,248.228,330.781Zm-20.92,3.847c2.294,3.9,4.7,8.558,5.317,11.045H221.991C222.6,343.187,225.015,338.527,227.308,334.628Zm0,17.516a5.474,5.474,0,0,1-5.372-4.467h10.745A5.475,5.475,0,0,1,227.308,352.144Zm10.46-18.982a1.378,1.378,0,1,1,1.379-1.378A1.38,1.38,0,0,1,237.768,333.162ZM253.7,346.675c0,.022,0,.042,0,.064v-.105C253.694,346.646,253.7,346.663,253.7,346.675Zm-.151-1H242.911c.612-2.486,3.024-7.146,5.317-11.045C250.521,338.527,252.932,343.187,253.545,345.673Zm-5.317,6.471a5.475,5.475,0,0,1-5.373-4.467H253.6A5.475,5.475,0,0,1,248.228,352.144Z"
              transform="translate(-219.835 -328.401)"
            />
          </svg>
          {!isEmpty(compare, "productIds") ? (
            <span className={styles.count}>{compare?.productIds.length}</span>
          ) : (
            ""
          )}
        </span>
      </a>
    </Link>
  );
};

export default CompareButton;
