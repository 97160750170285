import map from "lodash/map";

const LeadhubSetCart = (items) => {
	let products = [];

	map(items,(item) => {
		products.push({
			product_id: item?.product?.id,
			quantity: item?.count,
			value: item?.priceTotalData?.price,
			currency: item?.priceTotalData?.currency
		})
	})

	if ( window.lhi && items) {
		window.lhi('SetCart', {
			products: products
		});
	}

	return ""
}

const LeadhubIdentify = (user,formType = []) => {

	if ( window.lhi && user) {
		window.lhi('Identify', {
			email: user?.email,
			subscribe: formType,
			user_id: user?.attributes?.userId,
			first_name: user?.attributes?.name,
			last_name: user?.attributes?.surname,
			phone: user?.attributes?.phone
		});
	}

	return""
}

const LeadhubViewContent = (productId) => {
	if ( window.lhi && productId) {
		window.lhi('ViewContent', {
			products: [{
				product_id: productId,
			}]
		});
	}

	return""
}

const LeadhubViewCategory = (category) => {
	if ( window.lhi && category) {
		window.lhi('ViewCategory', {
			category: category
		});
	}

	return""
}

const LeadhubPurchase = (order) => {

	let products = [];

	map(order?.orderItems,(item) => {
		products.push({
			product_id: item?.productId,
			quantity: item?.count,
			value: item?.totalPrice,
			currency: item?.totalPriceData?.currency
		})
	})


	if ( window.lhi && order) {
		window.lhi('Purchase', {
			email: order?.email,
			value: order?.totalSum,
			currency: order?.totalSumData?.currency,
			products: products,
			order_id: order?.orderNumber,
			user_id: order?.email,
			subscribe: ['order'],
			first_name: order?.name,
			last_name: order?.surname,
			phone: order?.phone,
			address: {
				street: order?.street,
				city: order?.city,
				zip: order?.zip,
				country_code: order?.country
			}
		});
	}

	return""
}

export { LeadhubSetCart, LeadhubIdentify, LeadhubViewContent, LeadhubViewCategory, LeadhubPurchase };
