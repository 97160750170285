import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import { get, isEmpty, map } from "lodash";

import Link from "next/link";
import Image from "grandus-lib/components-atomic/image/Image";

const MegaMenu = ({ subCategories = [], styles = {} }) => {
  return (
    <div className={styles?.megamenu}>
      {map(subCategories, (subCategory) => {
        return (
          <div
            className={styles?.categoryList}
            key={`category-homepage-main-level1-${subCategory?.id}`}
          >
            <h4>
              <Link
                {...getCategoryLinkAttributes(subCategory?.urlName)}
                scroll={true}
              >
                <a>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.026"
                    height="21.969"
                    viewBox="0 0 34.026 21.969"
                    >
                    <path
                        d="M480.579,294.82V281.2a3.44,3.44,0,0,0-3.436-3.437H456.457a3.441,3.441,0,0,0-3.437,3.437V294.82h-3.234V296.3a3.441,3.441,0,0,0,3.437,3.437h27.152a3.441,3.441,0,0,0,3.437-3.437V294.82ZM455.043,281.2a1.415,1.415,0,0,1,1.414-1.413h20.685a1.415,1.415,0,0,1,1.413,1.413V294.82H455.043Zm25.333,16.509H453.224a1.417,1.417,0,0,1-1.3-.869H481.68A1.416,1.416,0,0,1,480.376,297.711Z"
                        transform="translate(-449.787 -277.765)"
                    />
                    </svg> */}
                  <Image
                    photo={get(subCategory, "mainPhoto", {})}
                    size={"45x45"}
                    type={"png"}
                  />
                  {subCategory?.name}
                </a>
              </Link>
            </h4>
            {!isEmpty(get(subCategory, "children", [])) ? (
              <ul>
                {map(get(subCategory, "children", []), (subSubCategory) => {
                  return (
                    <li
                      key={`category-homepage-main-level2-${subSubCategory?.id}`}
                    >
                      <Link
                        {...getCategoryLinkAttributes(subSubCategory?.urlName)}
                        scroll={true}
                      >
                        <a>{subSubCategory?.name}</a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MegaMenu;