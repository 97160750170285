import useSWR from "swr";
import { get, indexOf, remove, uniq, isEmpty } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";
import { getImageUrl } from "grandus-lib/utils";
import { useState } from "react";

import styles from "components/header/Header.module.scss";

import dynamic from "next/dynamic";
import MegaMenu from "./MegaMenu";
import { Divider } from "antd";
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const generateKey = (level = 0, id, key, suffix = "") =>
  `category-menu-level-${level}-${id}-${key}${suffix}`;

const LinkMobileAll = ({ name, urlName, onClickMethod }) => {
  return (
    <Link {...getCategoryLinkAttributes(urlName)} scroll={true}>
      <a className={styles.mobile} onClick={() => onClickMethod(false)}>
        {`Všetko z kategórie ${name}`}
      </a>
    </Link>
  );
};

const LinkMobile = ({ item, onClickMethod, onClickMethod2 }) => {
  const hasSubmenu = get(item, "children", []).length > 0;
  const hasSubmenuCssClass = hasSubmenu ? styles["has-submenu"] : "";
  return (
    <Link
      {...getCategoryLinkAttributes(
        item?.urlName,
        "",
        {},
        { absoluteHref: get(item, "externalUrl") }
      )}
      scroll={true}
    >
      <a
        className={hasSubmenuCssClass + " " + styles.mobile}
        onClick={(e) => {
          if (hasSubmenu) {
            e.preventDefault();
            onClickMethod(item?.id);
          } else {
            onClickMethod2(false);
          }
        }}
      >
        {item?.name}
      </a>
    </Link>
  );
};

const MenuContent = ({
  data = [],
  closeEvent = false,
  showMegamenu = false,
}) => {
  if (!data) {
    return <li>&nbsp;</li>;
  }
  return (
    <>
      {data.map((item, index) => {
        return (
          <li
            key={generateKey(0, item?.id, index)}
            className={[
              !isEmpty(get(item, "children", [])) ? styles?.hasMegamenu : "",
              item?.urlName.indexOf("vypredaj") >= 0 ? styles.sale : "",
            ].join(" ")}
          >
            <Link
              {...getCategoryLinkAttributes(
                item?.urlName,
                "",
                {},
                { absoluteHref: get(item, "externalUrl") }
              )}
              scroll={true}
            >
              <a
                onClick={(e) => {
                  if (closeEvent) {
                    closeEvent();
                  }
                }}
              >
                <Image
                  photo={get(item, "mainPhoto", {})}
                  size={"40x40"}
                  type={"png"}
                />
                {get(item, "name")}
              </a>
            </Link>
            {!isEmpty(get(item, "children", [])) && showMegamenu ? (
              <MegaMenu
                subCategories={get(item, "children", [])}
                styles={styles}
              />
            ) : (
              ""
            )}
          </li>
        );
      })}
    </>
  );
};

const Menu = ({ closeMenuEvent = false, options = {} }) => {
  const { data, error } = useSWR(
    "/api/lib/v1/categories",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const [openedCategories, setOpenedCategory] = useState([]);

  const onClickToggleOpenCategory = (categoryId) => {
    if (indexOf(openedCategories, categoryId) >= 0) {
      setOpenedCategory(remove(openedCategories, (n) => n != categoryId));
    } else {
      setOpenedCategory(uniq([...openedCategories, categoryId]));
    }
  };
  return (
    <>
      {get(options, "type") == "mobile" ? (
        <ul className={styles.mobileMenu}>
          <MenuContent data={data} closeEvent={closeMenuEvent} />
          <li>
            <Divider />
          </li>
          <li>
            <Link href="/prihlasenie">
              <a
                onClick={(e) => {
                  if (closeMenuEvent) {
                    closeMenuEvent();
                  }
                }}
              >
                Prihlásenie/Registrácia
              </a>
            </Link>
          </li>
        </ul>
      ) : (
        <div className={styles.categoriesWrapper} id="categoryMenu">
          <div className={[styles.container, "custom-container"].join(" ")}>
            <ul>
              <MenuContent data={data} showMegamenu />
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
